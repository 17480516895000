.bold {
  font-weight: 600;
}
:root {
  --color-primary-dark: #10256a;
  --color-primary-white: #fefefe;
}
.color-dark {
  color: var(--color-primary-dark);
}
.background-color-dark {
  background-color: var(--color-primary-dark);
}
.color-white {
  color: var(--color-primary-white);
}
.background-color-white {
  background-color: var(--color-primary-white);
}

.navbar2_component {
  background-color: var(--color-primary-white) !important;
}
.navbar2_menu a {
  color: var(--color-primary-dark) !important;
}

.faq_faq_question {
  border-top: 1px solid var(--color-primary-dark) !important;
}
.faq_faq_list {
  border-bottom: 1px solid var(--color-primary-dark) !important;
}
.about-us_benefits_content-item,
.about-us_cta_card {
  border-color: var(--color-primary-dark) !important;
}

.dark-background-secondary {
  border: 1px solid var(--color-primary-white) !important;
}

.home_feature_image-wrapper,
.services_2_feature_image-wrapper {
  height: 100%;
}

.contact-us_contact-form_component input,
textarea {
  border-color: var(--color-primary-dark) !important;
}
.w-checkbox-input {
  border-color: var(--color-primary-dark) !important;
}
